// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Modal.css */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    position: relative;
    background: #ffffff;
    max-width: 40%;
    height: auto;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    width: 100%;
}
.modal-close {
    position: absolute;
    top: -54px;
    right: -29px;
    font-size: 37px;
    cursor: pointer;
    color: white;
}
.modal-content img {
    width: 100%;
    height: auto;
    display: block;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Home/Modal.css"],"names":[],"mappings":"AAAA,cAAc;AACd;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,8BAA8B;IAC9B,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;AACjB;;AAEA;IACI,kBAAkB;IAClB,mBAAmB;IACnB,cAAc;IACd,YAAY;IACZ,uCAAuC;IACvC,WAAW;AACf;AACA;IACI,kBAAkB;IAClB,UAAU;IACV,YAAY;IACZ,eAAe;IACf,eAAe;IACf,YAAY;AAChB;AACA;IACI,WAAW;IACX,YAAY;IACZ,cAAc;AAClB","sourcesContent":["/* Modal.css */\n.modal-overlay {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background: rgba(0, 0, 0, 0.8);\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    z-index: 1000;\n}\n\n.modal-content {\n    position: relative;\n    background: #ffffff;\n    max-width: 40%;\n    height: auto;\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);\n    width: 100%;\n}\n.modal-close {\n    position: absolute;\n    top: -54px;\n    right: -29px;\n    font-size: 37px;\n    cursor: pointer;\n    color: white;\n}\n.modal-content img {\n    width: 100%;\n    height: auto;\n    display: block;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
