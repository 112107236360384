
   
import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS CSS for styles
import {Link} from 'react-router-dom';
// import speech1 from '../../assets/img/SPEECH-IMAGES.jpg';
// import blog from '../../assets/background/FARM-MANAGEMENT1.jpg';
// import blog2 from '../../assets/img/icar.jpg';

function CardList() {
  // Array of card data
  const cards = [
    {
      id: 1,
      imgSrc: "https://webpagecdnstorage.blob.core.windows.net/ecrop/SPEECH-IMAGES.jpg",
      title: 'Technology Transfer of eCrop Module to PRECISION GROW on World Environment Day',
      // text: 'Some quick example text to build on the card title and make up the bulk of the card\'s content.',
      date: 'July 23, 2024', // Date for Card 1
      size: '30rem'
    },
    {
      id: 2,
      imgSrc: "https://webpagecdnstorage.blob.core.windows.net/ecrop/SPEECH-IMAGES.jpg",
      title: 'ICAR CTCRI Celebrates 61st Foundation Day in the Presence of the.. ​',
      // text: 'Another card with some quick example text.',
      date: 'July 24, 2024', // Date for Card 2
      size: '30rem'
    },
    {
      id: 3,
      imgSrc: "https://webpagecdnstorage.blob.core.windows.net/ecrop/SPEECH-IMAGES.jpg",
      title: 'eCrop - Your Own Agriculture Assistant.. ​',
      // text: 'This card is a bit longer to demonstrate card content variations in a list.',
      date: 'July 25, 2024', 
      size: '30rem'
    }
  ];

  useEffect(() => {
    // Initialize AOS
    AOS.init({
      duration: 1000,  
      easing: 'ease-in-out',  
    });
  }, []);

  return (
    <div>
      {/* Background Banner */}
      <div className="banner" style={{ backgroundImage: `url("https://webpagecdnstorage.blob.core.windows.net/ecrop/FARM-MANAGEMENT1.jpg")`, backgroundSize: 'cover', backgroundPosition: 'center', minHeight: '400px', display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', color: 'white' }}>
        <h1 className="text-white" data-aos="fade-up" style={{ fontFamily: 'Merriweather', fontWeight: 'bold', fontSize: '3rem' }}>Blog</h1>
      </div>

      {/* Card Container */}
      <div className="container mt-4">
        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4">
          {cards.map(card => (
            <div key={card.id} className="col mb-4" data-aos="fade-up">
              <div className="card h-100">
                <img src={card.imgSrc} className="card-img-top" alt="Card" style={{ height: '250px', objectFit: 'cover' }} />
                <div className="card-body d-flex flex-column">
                  <h5 className="card-title">{card.title}</h5>
                  {/* <p className="card-text">{card.text}</p> */}
                  <div className="mt-auto">
                    <Link to="/" className="btn btn-primary btn-success align-self-end">Details</Link>
                  </div>
                  <div className="mt-3">
                    <p className="card-date">Published on: {card.date}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default CardList;
