 import React from 'react'
import { Link } from 'react-router-dom'
import './Subnavbar.css';
 function Subnavbar() {
   return (
     <>
<nav className="navbar navbar-expand-lg bg-body-tertiary sticky-top">
  <div className="container-fluid">
 
    <img className="logo" loading="lazy" src="https://webpagecdnstorage.blob.core.windows.net/ecrop/logooo.svg" alt="logo" />

    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarSupportedContent">
      <ul className="navbar-nav me-auto mb-2 mb-lg-0">
        <li className="nav-item">
          <Link className="nav-link active" aria-current="page" to="/">Home</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/about">About</Link>
        </li>
        <li className="nav-item dropdown">
          <Link className="nav-link" to="/services">
          Services
          </Link>
          <ul className="dropdown-menu">
          <li><Link to="/farmmanagement" className="dropdown-item">Farm Management</Link></li>
          <li><Link to="/ApplicationOfDevice" className="dropdown-item">Application Of Device</Link></li>
          <li><Link to="/interface" className="dropdown-item">E-crop Interface</Link></li>
          </ul>
        </li>
        <li className="nav-item dropdown">
          <Link className="nav-link" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Products
          </Link>
          <ul className="dropdown-menu">
            <li><Link to="/ecrop" className="dropdown-item" href="#">e-CROP</Link></li>
          </ul>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/sustainability">Sustainability</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/gallery">Gallery</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="https://precisiongrowindia.blogspot.com/" target="_blank">Blog</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/faq">FAQ</Link>
        </li>
        {/* <li className="nav-item">
          <Link className="nav-link" to="#">Contact</Link>
        </li> */}
        <li className="nav-item dropdown">
          <Link className="nav-link  " to="/contact" role="button"   aria-expanded="false">
            Contact
          </Link>
         
        </li>
        
      </ul>
      <form className="d-flex" role="search">
        <input className="form-control me-2" type="search" placeholder="Search Keyword" aria-label="Search"/>
        <button className="btn btn-outline-success" type="submit"><i class="fa-solid fa-magnifying-glass"></i></button>
      </form>
    </div>
  </div>
</nav>
     </>
   )
 }
 
 export default Subnavbar