// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logo{
    width:18%;
}
nav{
    width:100%;
    margin:0 auto;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2),
            2px 2px 4px rgba(0, 0, 0, 0.3);
}
nav ul li{
    font-weight: 500;
}
.navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
    color: #139f2f!important;
}
.navbar-expand-lg .navbar-nav .nav-link {
   margin-left: 15px!important;
    font-size: 18px;
    position: relative; /* Ensure positioning context for pseudo-element */
}

.nav-link::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 1px;
    background-color: #139f2f;
    transition: width 0.3s ease-in-out; /* Adjust timing and easing as needed */
}
.nav-link:hover{
    color: #2cb448 !important;
}
.nav-link:hover::before {
    width: 100%; /* Expand to full width on hover */
}
.form-control {
    color: white;
    appearance: none;
    background-color: #8080805e;
    border: var(--bs-border-width) solid #2b9240}
    
  .dropdown:hover .dropdown-menu{
    display: block;
    padding:10px;
    border:none;
    border-radius:0px;
  }

  .dropdown-item:active, .dropdown-item:hover {
    color: var(--bs-dropdown-link-active-color);
    text-decoration: none;
    background-color: #ffc107!important;
    padding:0px 5px;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Headfoot/Navbar/Subnavbar.css"],"names":[],"mappings":"AAAA;IACI,SAAS;AACb;AACA;IACI,UAAU;IACV,aAAa;IACb;0CACsC;AAC1C;AACA;IACI,gBAAgB;AACpB;AACA;IACI,wBAAwB;AAC5B;AACA;GACG,2BAA2B;IAC1B,eAAe;IACf,kBAAkB,EAAE,kDAAkD;AAC1E;;AAEA;IACI,WAAW;IACX,kBAAkB;IAClB,SAAS;IACT,OAAO;IACP,QAAQ;IACR,WAAW;IACX,yBAAyB;IACzB,kCAAkC,EAAE,uCAAuC;AAC/E;AACA;IACI,yBAAyB;AAC7B;AACA;IACI,WAAW,EAAE,kCAAkC;AACnD;AACA;IACI,YAAY;IACZ,gBAAgB;IAChB,2BAA2B;IAC3B,4CAA4C;;EAE9C;IACE,cAAc;IACd,YAAY;IACZ,WAAW;IACX,iBAAiB;EACnB;;EAEA;IACE,2CAA2C;IAC3C,qBAAqB;IACrB,mCAAmC;IACnC,eAAe;AACnB","sourcesContent":[".logo{\n    width:18%;\n}\nnav{\n    width:100%;\n    margin:0 auto;\n    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2),\n            2px 2px 4px rgba(0, 0, 0, 0.3);\n}\nnav ul li{\n    font-weight: 500;\n}\n.navbar-nav .nav-link.active, .navbar-nav .nav-link.show {\n    color: #139f2f!important;\n}\n.navbar-expand-lg .navbar-nav .nav-link {\n   margin-left: 15px!important;\n    font-size: 18px;\n    position: relative; /* Ensure positioning context for pseudo-element */\n}\n\n.nav-link::before {\n    content: '';\n    position: absolute;\n    bottom: 0;\n    left: 0;\n    width: 0;\n    height: 1px;\n    background-color: #139f2f;\n    transition: width 0.3s ease-in-out; /* Adjust timing and easing as needed */\n}\n.nav-link:hover{\n    color: #2cb448 !important;\n}\n.nav-link:hover::before {\n    width: 100%; /* Expand to full width on hover */\n}\n.form-control {\n    color: white;\n    appearance: none;\n    background-color: #8080805e;\n    border: var(--bs-border-width) solid #2b9240}\n    \n  .dropdown:hover .dropdown-menu{\n    display: block;\n    padding:10px;\n    border:none;\n    border-radius:0px;\n  }\n\n  .dropdown-item:active, .dropdown-item:hover {\n    color: var(--bs-dropdown-link-active-color);\n    text-decoration: none;\n    background-color: #ffc107!important;\n    padding:0px 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
