import React from 'react'
import Subnavbar from './Subnavbar'
import Topnavbar from './Topnavbar'
function Navbar() {
  return (
    <>
    <Topnavbar/>
    <Subnavbar/>
    
    </>
  )
}

export default Navbar