import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './Gallery.css';
const Gallery = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: 'ease-in-out',
      once: true,
      mirror: false,
    });
  }, []);

  return (

    <div class="grid-gallary">
    <div class="grid-item">
        <img src="https://webpagecdnstorage.blob.core.windows.net/ecrop/36_Transparency.webp" alt=""/>
   
    </div>
    <div class="grid-item">
    
        <img src="https://webpagecdnstorage.blob.core.windows.net/ecrop/36_Transparency.webp" alt=""/>
    
    </div>
    <div class="grid-item">
  
        <img src="https://webpagecdnstorage.blob.core.windows.net/ecrop/36_Transparency.webp" alt=""/>
 
    </div>
    
</div>
     
  );
};

export default Gallery;