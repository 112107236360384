// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.grid-gallary {
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
    padding: 50px 20px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-auto-rows: 250px;
    grid-auto-flow: dense;
    grid-gap: 20px;
    /* background-color: red; */
}

.grid-gallary .grid-item {
    position: relative;
    background-color: #efefef;
    overflow: hidden;
}

.grid-gallary .grid-item:nth-child(3n - 2) {
    grid-column: span 2;
    grid-row: span 2;
}
.grid-gallary .grid-item:nth-child(3n - 2) {
    grid-column: span 2;
    grid-row: span 2;
}

.grid-gallary .grid-item {
    position: relative;
    background-color: #efefef;
    overflow: hidden;
}

.grid-gallary .grid-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
}`, "",{"version":3,"sources":["webpack://./src/Components/Gallery/Gallery.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,iBAAiB;IACjB,cAAc;IACd,kBAAkB;IAClB,aAAa;IACb,2DAA2D;IAC3D,qBAAqB;IACrB,qBAAqB;IACrB,cAAc;IACd,2BAA2B;AAC/B;;AAEA;IACI,kBAAkB;IAClB,yBAAyB;IACzB,gBAAgB;AACpB;;AAEA;IACI,mBAAmB;IACnB,gBAAgB;AACpB;AACA;IACI,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;IAClB,yBAAyB;IACzB,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,iBAAiB;IACjB,6DAA6D;AACjE","sourcesContent":[".grid-gallary {\n    width: 100%;\n    max-width: 1400px;\n    margin: 0 auto;\n    padding: 50px 20px;\n    display: grid;\n    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));\n    grid-auto-rows: 250px;\n    grid-auto-flow: dense;\n    grid-gap: 20px;\n    /* background-color: red; */\n}\n\n.grid-gallary .grid-item {\n    position: relative;\n    background-color: #efefef;\n    overflow: hidden;\n}\n\n.grid-gallary .grid-item:nth-child(3n - 2) {\n    grid-column: span 2;\n    grid-row: span 2;\n}\n.grid-gallary .grid-item:nth-child(3n - 2) {\n    grid-column: span 2;\n    grid-row: span 2;\n}\n\n.grid-gallary .grid-item {\n    position: relative;\n    background-color: #efefef;\n    overflow: hidden;\n}\n\n.grid-gallary .grid-item img {\n    width: 100%;\n    height: 100%;\n    object-fit: cover;\n    transition: transform 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
