import React from 'react';
import './Modal.css'; // Import the CSS for the modal
function Modal({ isOpen, onClose }) {
    if (!isOpen) return null;

    return (
        <div className="modal-overlay" onClick={onClose}>
            <div className="modal-content" onClick={e => e.stopPropagation()}>
                <span className="modal-close" onClick={onClose}>&times;</span>
                <img src="https://webpagecdnstorage.blob.core.windows.net/ecrop/e-crop-ad.jpg" alt="Modal Content" />
            </div>
        </div>
    );
}

export default Modal;
